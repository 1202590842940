<div class="app">
  <header>
    <div class="header" *ngIf="connected">
      <mat-toolbar class="toolbar">
        <al-menu-widget></al-menu-widget>
        <div class="logo">
          <img alt="Air Liquide" src="assets/images/logo_airliquide_header_horizontal.png" class="default">
        </div>
        <span class="app-title">eRound</span>
        <al-site-selector></al-site-selector>
        <span class="spacer"></span>
        <span class="rounds">
          <span class="help">
            <mat-icon (click)="openLink()"
                      class="icon-help"
                      [matTooltip]="'HELP'| translate">help</mat-icon>

</span>

          <al-account-widget></al-account-widget>

        </span>

      </mat-toolbar>


    </div>
  </header>

  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <footer>
    <div class="bottom">
      Version: {{ version }}
    </div>
  </footer>
  <al-spinner></al-spinner>

</div>
