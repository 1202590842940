<div class="login">
  <div class="wrapper">
    <img src="assets/images/logo_airliquide.png" alt="Air Liquide">
    <h1>{{title}}</h1>
    <div class="al-session-cas">
      <span class="text" (click)="connect()">{{'LOGIN'|translate}}</span>
    </div>
    <br>
    <div>
      <a href="https://airliquide.service-now.com/myitcorner" target="_blank" style="color: inherit"> My IT Corner</a>
    </div>
  </div>
</div>
