// date-adapter.ts
import { DateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Site } from '@al/model';
import { SiteService } from '@al/services';
import moment from 'moment-timezone';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Injectable({
  providedIn: 'root',
})
export class CustomNativeDateAdapter extends MomentDateAdapter {
  // export class CustomNativeDateAdapter extends NativeDateAdapter {
  public site: Site = new Site();

  public constructor(protected siteService: SiteService) {
    super(localStorage.getItem('locale')!);
    this.siteService.getCurrentSiteObservable().subscribe((site) => {
      this.site = site;
      moment.tz.setDefault(this.site.timezone);
    });
  }

  // public override getYear(date: Date): number {
  //   return 1;
  // }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public override format(date: moment.Moment, displayFormat: Object): string {
    let format = this.site.dateFormat.substring(0, 10);
    let tmp = moment.tz(date.valueOf(), this.site.timezone).clone();
    let dateFormated = tmp.format(format);
    // let dateFormated = moment(date).format(format);
    return dateFormated;
  }

  public override setLocale(locale: any) {
    super.setLocale(locale);
  }
}

export const GlobalCustomDateProvider = [
  {
    provide: DateAdapter,

    useClass: CustomNativeDateAdapter,
  },
];
