<span class="account">
  <button [matMenuTriggerFor]="mainMenu" [matTooltip]="fullName" mat-icon-button attr.aria-label="{{'USER_SETTINGS' | translate}}"
   [ngStyle]="buttonStyle">
    <mat-icon *ngIf="!hasImage">person</mat-icon>
  </button>
</span>


<mat-menu #mainMenu="matMenu">
  <div class="panel">
    <button mat-menu-item [matMenuTriggerFor]="accountMenu" translate *ngIf="this.isConnectedWithUserPool">
      <mat-icon>shield</mat-icon>
      <span>{{'ACCOUNT_SECURITY_MENU' | translate}}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="languageMenu" translate>
      <mat-icon>translate</mat-icon>
      <span translate>ACCOUNT_LANGUAGE_MENU</span>
    </button>
    <span mat-menu-item>
      <mat-icon>help</mat-icon>
      <a href="https://airliquide.service-now.com/myitcorner" target="_blank" style="color: inherit" translate>GO_TO_IT_CORNER</a>
    </span>
    <button mat-menu-item (click)="openDialogWithRef(logoutDialog)" translate>
      <mat-icon>logout</mat-icon>
      <span translate>ACCOUNT_QUIT_MENU</span>
    </button>


  </div>
</mat-menu>

<mat-menu #languageMenu="matMenu">
  <div class="panel">
    <a mat-menu-item (click)="setLanguage('en')">
      <span class="fi fi-gb" translate></span>
      <span translate>LANGUAGE_ENGLISH</span>
    </a>
    <a mat-menu-item (click)="setLanguage('fr')">
      <span class="fi fi-fr" translate></span>
      <span translate> LANGUAGE_FRENCH</span>
    </a>
    <a mat-menu-item (click)="setLanguage('de')">
      <span class="fi fi-de" translate></span>
      <span translate>LANGUAGE_GERMAN</span>
    </a>
    <a mat-menu-item (click)="setLanguage('it')">
      <span class="fi fi-it" translate></span>
      <span translate>LANGUAGE_ITALIAN</span>
    </a>
    <a mat-menu-item (click)="setLanguage('ja')">
      <span class="fi fi-jp" translate></span>
      <span translate>LANGUAGE_JAPANESE</span>
    </a>
    <a mat-menu-item (click)="setLanguage('nl')">
      <span class="fi fi-nl" translate></span>
      <span translate>LANGUAGE_DUTCH</span>
    </a>
    <a mat-menu-item (click)="setLanguage('cn')">
      <span class="fi fi-cn" translate></span>
      <span translate>LANGUAGE_CHINA</span>
    </a>
    <a mat-menu-item (click)="setLanguage('bl')">
      <span class="fi fi-bg" translate></span>
      <span translate>LANGUAGE_BULGARIE</span>
    </a>
    <a mat-menu-item (click)="setLanguage('gr')">
      <span class="fi fi-gr" translate></span>
      <span translate>LANGUAGE_GREEK</span>
    </a>
	    <a mat-menu-item (click)="setLanguage('pt')">
      <span class="fi fi-pt" translate></span>
      <span translate>LANGUAGE_PORTUGUESE</span>
    </a>
	<a mat-menu-item (click)="setLanguage('es')">
      <span class="fi fi-es" translate></span>
      <span translate>LANGUAGE_SPANISH</span>
    </a>
	<a mat-menu-item (click)="setLanguage('pl')">
      <span class="fi fi-pl" translate></span>
      <span translate>LANGUAGE_POLISH</span>
    </a>
		<a mat-menu-item (click)="setLanguage('ru')">
      <span class="fi fi-ru" translate></span>
      <span translate>LANGUAGE_RUSSIAN</span>
    </a>
	<a mat-menu-item (click)="setLanguage('tr')">
      <span class="fi fi-tr" translate></span>
      <span translate>LANGUAGE_TURKISH</span>
    </a>
	<a mat-menu-item (click)="setLanguage('da')">
      <span class="fi fi-da" translate></span>
      <span translate>LANGUAGE_DANISH</span>
    </a>
    <a mat-menu-item (click)="setLanguage('template')" *ngIf="isDevelopment">
      <span class="fi fi-ja" translate></span>
      <span translate>NO_KEYS</span>
    </a>
  </div>
</mat-menu>

<mat-menu #accountMenu="matMenu">
  <div class="panel">
    <button mat-menu-item translate (click)="openResetPasswordDialog()">
      <mat-icon>password</mat-icon>
      <span translate>ACCOUNT_SECURIT_CHANGE_PASSWORD_MENU</span>
    </button>
  </div>
</mat-menu>

<ng-template #logoutDialog>
  <al-confirm-dialog (confirm)='logout()'>
    <div title translate>QUIT</div>
    <div content translate>AGREE_TO_QUIT_MSG</div>
    <div cancel-button translate>NO</div>
    <div confirm-button translate>YES</div>
  </al-confirm-dialog>
</ng-template>

