<mat-card class="al-template-frequency-cron">
  <mat-card-header>
    <mat-card-title translate>FREQUENCY</mat-card-title>
    <mat-icon (click)="openFrequency()">edit</mat-icon>
  </mat-card-header>

  <div *ngIf="frequency.type === this.extendedFrequencyType.NONE">
    {{ "NO_FREQUENCY" | translate }}
  </div>

  <div *ngIf="frequency.type === this.extendedFrequencyType.ONE_SHOT">
    {{ "ONE_SHOT_FREQUENCY" | translate }}
  </div>

  <div *ngIf="frequency.type === this.extendedFrequencyType.HOURLY">
    {{
      "HOURLY_FREQUENCY_MESSAGE" | translate : { nbHour: frequency.interval }
    }}
  </div>
  <div *ngIf="frequency.type === this.extendedFrequencyType.DAILY">
    {{ "DAILY_FREQUENCY_MESSAGE" | translate }}
  </div>
  <div *ngIf="frequency.type === this.extendedFrequencyType.WEEKLY">
    {{ "WEEKLY_FREQUENCY_MESSAGE" | translate }}
    {{ this.frequency.executionDay!.label | translate }}
  </div>

  <div *ngIf="frequency.type === this.extendedFrequencyType.MONTHLY_DATE">
    {{ "MONTHLY_DATE_FREQUENCY_MESSAGE" | translate }}
  </div>
  <div *ngIf="frequency.type === this.extendedFrequencyType.MONTHLY_DAY">
    {{ "MONTHLY_DAY_FREQUENCY_MESSAGE" | translate }}
    {{ frequency.occurency!.label | translate }}
    {{ frequency.executionDay!.label | translate }}
  </div>
  <div *ngIf="frequency.type === this.extendedFrequencyType.EVERY_X_DAYS">
    {{
      "EVERY_X_DAYS_FREQUENCY_MESSAGE"
        | translate : { nbDays: frequency.interval }
    }}
  </div>
  <div *ngIf="frequency.type === this.extendedFrequencyType.EVERY_X_WEEKS">
    {{
      "EVERY_X_WEEKS_FREQUENCY_MESSAGE"
        | translate : { nbWeeks: frequency.interval }
    }}
  </div>
  <div *ngIf="frequency.type === this.extendedFrequencyType.EVERY_X_MONTHS">
    {{
      "EVERY_X_MONTHS_FREQUENCY_MESSAGE"
        | translate : { nbMonths: frequency.interval }
    }}
  </div>
  <div *ngIf="frequency.useDateFilter">
    {{
      "DATE_FREQUENCY_MESSAGE"
        | translate
          : {
              startDate: getDate(frequency.startDate),
              endDate: getDate(frequency.endDate)
            }
    }}
  </div>
  <div *ngIf="frequency.useDayFilter">
    {{ "APPLICABLE_DAYS_FREQUENCY_MESSAGE" | translate }}
    <span *ngFor="let day of frequency.daysOfTheWeek">
      {{ day.label | translate }}</span
    >
  </div>
  <div *ngIf="frequency.useTimeFilter">
    {{
      "TIME_FREQUENCY_MESSAGE"
        | translate
          : {
              startExecutionTime: frequency.startExecutionTime,
              endExecutionTime: frequency.endExecutionTime
            }
    }}
    <!--    <span *ngFor="let day of frequency.daysOfTheWeek"> {{day.label | translate}}</span>-->
  </div>

  <div *ngIf="frequency.executionTime">
    {{
      "EXECUTION_TIME_FREQUENCY_MESSAGE"
        | translate : { executionTime: frequency.executionTime }
    }}
    <!--    <span *ngFor="let day of frequency.daysOfTheWeek"> {{day.label | translate}}</span>-->
  </div>

  <div *ngIf="frequency.executionDate">
    {{
      "EXECUTION_TIME_FREQUENCY_MESSAGE"
        | translate
          : { executionTime: getDateWithHour(frequency.executionDate) }
    }}
    <!--    <span *ngFor="let day of frequency.daysOfTheWeek"> {{day.label | translate}}</span>-->
  </div>

  <!--<mat-card class="al-template-frequency" [formGroup]="formGroup">-->

  <!--  <div class="buttons">-->
  <!--    <button mat-button aria-label="Clear" class="button"-->
  <!--            type="button" (click)="openFrequency()">-->
  <!--      {{ 'FREQUENCIES' | translate }}-->

  <!--      <mat-icon floatLabel="always" appearance="outline"-->
  <!--                class="material-icons-outlined"-->
  <!--      >schedule-->
  <!--      </mat-icon>-->
  <!--    </button>-->

  <!--  </div>-->
</mat-card>
