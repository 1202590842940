import { ApplySiteTimezonePipePipe, ToDatePipe } from '@al/pipe';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';

import { LOCALE_PROVIDER, LocaleService } from './locale.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AccountWidgetModule } from '@al/account-widget';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AlSpinnerModule } from '@al/spinner';
import Amplify from 'aws-amplify';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CustomNativeDateAdapter } from './custom-date.adapter';
import { DashboardModule } from '@al/dashboard';
import { DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { GoogleModule } from '@al/google';
import { LoginModule } from '@al/login';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MenuWidgetModule } from '@al/menu-widget';
import { NgModule } from '@angular/core';
import { PdfModule } from '@al/pdf';
import { PyrometreComponentsModule } from '@al/pyrometre-components';
import { ReferentielModule } from '@al/referentiel';
import { RouterModule } from '@angular/router';
// import { SchedulerComponentModule } from '@al/scheduler-component';
import { SecurityHttpInterceptor } from './core/interceptor/security-http.interceptor';
import { SiteComponentsModule } from '@al/site-components';
import { SiteSelectorModule } from '@al/site-selector-widget';
import { TemplateComponentsModule } from '@al/template-components';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UsersComponentsModule } from '@al/users-components';
import awsConfig from './environments/aws-exports';

Amplify.configure(awsConfig);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/DD/MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [AppComponent],

  imports: [
    AccountWidgetModule,
    AkitaNgDevtools.forRoot({ shallow: true }),
    AlSpinnerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    DashboardModule,
    DragDropModule,
    GoogleModule,
    HttpClientModule,
    LoginModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MenuWidgetModule,
    PdfModule,
    ReferentielModule,
    RouterModule,
    SiteComponentsModule,
    SiteSelectorModule,
    TemplateComponentsModule,
    PyrometreComponentsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SiteComponentsModule,
    UsersComponentsModule,
    SiteSelectorModule,
  ],
  providers: [
    DatePipe,
    ApplySiteTimezonePipePipe,
    ToDatePipe,
    LocaleService,
    LOCALE_PROVIDER,
    {
      provide: DateAdapter,
      useClass: CustomNativeDateAdapter,
      // deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    // GlobalCustomDateProvider,
    // CustomDateFormatProvider,
    // {
    //   provide: MAT_DATE_LOCALE,
    //   useClass: MY_FORMATS,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityHttpInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
