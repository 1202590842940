import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  Category,
  FrequencyType,
  Profile,
  Site,
  TemplateLight,
  User,
} from '@al/model';
import { SiteService, TemplateLightService, UserService } from '@al/services';
import { take, takeUntil } from 'rxjs/operators';
import { FormControlNames } from '../form-control-names.enum';
import { GenericListComponent } from '@al/generic-components';
import { LocalStorageKey } from '@al/local-cache';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Paths } from '@al/angular-route';
import { ReferentielService } from '@al/referentiel';
import { Sort } from '@angular/material/sort';
import { TemplateCreationDialogComponent } from '../template-creation-dialog/template-creation-dialog.component';
import { TemplateFilterService } from '../service/template-filter.service';

@Component({
  selector: 'al-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})
export class TemplateListComponent
  extends GenericListComponent<TemplateLight>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('exportPDFDialogKo', { static: true })
  public exportPDFDialogKo!: TemplateRef<any>;

  @ViewChild('historyDialog')
  public historyDialog!: TemplateRef<any>;

  @ViewChild('pdfDialog', { static: true })
  public pdfDialog: TemplateRef<any> | undefined;

  public categories: Category[] = [];

  public currentUser: Observable<User | undefined>;

  public dateFormat: string;

  public formControlNames = FormControlNames;

  public frequencyPolicy = [
    { label: FrequencyType.NONE, id: FrequencyType.NONE },
    { label: FrequencyType.HOURLY, id: FrequencyType.HOURLY },
    { label: FrequencyType.DAILY, id: FrequencyType.DAILY },
    { label: FrequencyType.WEEKLY, id: FrequencyType.WEEKLY },
    { label: FrequencyType.MONTHLY_DATE, id: FrequencyType.MONTHLY_DATE },
    { label: FrequencyType.MONTHLY_DAY, id: FrequencyType.MONTHLY_DAY },
    { label: FrequencyType.CUSTOM, id: FrequencyType.CUSTOM },
  ];

  public selectedItem!: Site | undefined;

  public selectedItemUuid: string = '';

  public templateIdForHistory: string | number = 0;

  public userProfile: Profile | undefined;

  public visibility = [
    { label: 'DISABLED', id: false },
    { label: 'ENABLED', id: true },
  ];

  public constructor(
    protected templateFilterService: TemplateFilterService, //
    protected templateService: TemplateLightService, //
    protected override router: Router,
    protected override route: ActivatedRoute,
    public dialog: MatDialog,
    protected userService: UserService,
    protected siteService: SiteService,
    private cdref: ChangeDetectorRef,
  ) {
    super(templateFilterService, templateService, router, route);
    let referentielService = new ReferentielService();
    this.categories = referentielService.categories;
    this.dateFormat = localStorage[LocalStorageKey.DEFAULT_DATEFORMAT];
    this.currentUser = this.userService.getCurrentObservable();
    this.siteService
      .getCurrentSiteObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((site: Site) => {
        this.selectedItem = site;
        this.selectedItemUuid = site.id;
      });

    this.userService
      .getCurrentObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User | undefined) => {
        this.userProfile = user?.profileType;
      });
  }

  public copySite(element: TemplateLight) {
    this.templateService.copySite(element, this.selectedItemUuid);
  }

  public createTemplate(): void {
    const dialogRef = this.dialog.open(TemplateCreationDialogComponent);
    dialogRef
      .afterClosed()
      .subscribe((result: { furnace: string; pycoso: boolean } | null) => {
        if (result) {
          this.router.navigate([
            '/',
            Paths.template.DEFAULT,
            Paths.template.CREATE,
          ]);
        }
      });
  }

  public deleteTemplate(template: TemplateLight) {
    this.templateService.delete(template).subscribe({
      next: () => {
        this.openDialogInfo(this.deleteDialogOk);
      },
      error: () => {
        this.openDialogInfo(this.deleteDialogKo);
      },
    });
  }

  public exportPdf(id: number) {
    if (this.pdfDialog) {
      this.dialog.open(this.pdfDialog, {
        height: '200px',
        width: '400px',
      });
    }
    this.templateService
      .exportPdf(id)
      .pipe(take(1))
      // .subscribe((response) => {
      // });
      .subscribe({
        next: (response) => {
          this.dialog.closeAll();
          window.open(response.body.presignedUrl, '_blank');
        },
        error: (error) => {
          console.error('export pdf', error);
          this.openDialogInfo(this.exportPDFDialogKo);
        },
      });
  }

  public filterCategory($event: any, field: string): void {
    this.addFilter(field, $event, (value: TemplateLight) =>
      this.categoryCompare(value, $event),
    );
  }

  public filterDate($event: any, field: string): void {
    this.addFilter(
      field,
      $event,
      (value: TemplateLight) => value.lastInstanceValidation! == $event,
    );
  }

  public filterFrequency($event: any, field: FormControlNames) {
    this.addFilter(
      field,
      $event,
      (value: TemplateLight) => this.frequencyCompare(value, $event),

      // value.frequency?.type?.toLowerCase().includes($event.toLowerCase()),
    );
  }

  public filterId($event: any, field: string): void {
    this.addFilter(field, $event, (value: TemplateLight) =>
      value.id
        .toString()
        .toLowerCase()
        .includes($event.toString().toLowerCase()),
    );
  }

  public filterLabel($event: string, field: string): void {
    this.addFilter(field, $event, (value: TemplateLight) =>
      value.title!.toLowerCase().includes($event.toString().toLowerCase()),
    );
  }

  public filterLocation($event: string, field: string): void {
    this.addFilter(field, $event, (value: TemplateLight) =>
      value.location?.toLowerCase().includes($event.toLowerCase()),
    );
  }

  public filterTitle($event: string, field: string): void {
    this.addFilter(field, $event, (value: TemplateLight) =>
      value.title?.toLowerCase().includes($event.toLowerCase()),
    );
  }

  public filterVisibility($event: any, field: string): void {
    // this.addFilter(
    //   field,
    //   $event,
    //   (value: TemplateLight) => value.visible! === $event
    // );
    this.addFilter(field, $event, (value: TemplateLight) =>
      $event.includes(value.visible),
    );
  }

  public formatDate(): string {
    return '';
  }

  public goToHistories(): void {
    this.akitaFilterService
      .getFilter()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        let ids: number[] = [];
        data.forEach((e) => {
          ids.push(e.id);
        });
        this.templateService.setActives(ids);
        this.router.navigate([Paths.template.HISTORIES], {
          relativeTo: this.route.parent,
        });
      });
  }

  public goToHistory(id: string | number): void {
    this.templateService.setActive(id);
    this.templateIdForHistory = id;
    // this.openDialogInfo(this.historyDialog);
    //
    this.router.navigate([Paths.template.HISTORY + `/${id}`], {
      relativeTo: this.route.parent,
    });
  }

  public override ngAfterViewInit(): void {
    if (this.sort) {
      this.dataSource.sort = this.sort;
      const sortState: Sort = {
        active: FormControlNames.id,
        direction: 'desc',
      };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
    }
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    this.cdref.detectChanges();
  }

  public openDialogInfo(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  public openDialogWithRef(
    ref: TemplateRef<any>,
    template: TemplateLight,
    isCopy: boolean = false,
  ) {
    if (isCopy) {
      this.selectedItemUuid = this.siteService.getCurrentSite()?.id as string;
    }
    this.dialog.open(ref, {
      data: template,
    });
  }

  protected initColumnDisplay(): string[] {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Last round validation date':
          if (item.lastInstanceValidation) {
            return new Date(item.lastInstanceValidation).getTime();
          }
          return item[property as keyof TemplateLight] as string;
        case 'category':
          if (item.category) {
            return item.category.label;
          }
          return item[property as keyof TemplateLight] as string;
        default:
          return item[property as keyof TemplateLight] as string;
      }
    };

    return [
      FormControlNames.id,
      FormControlNames.location,
      FormControlNames.category,
      FormControlNames.title,
      FormControlNames.visibility,
      FormControlNames.date,
      FormControlNames.changeDate,
      FormControlNames.frequencyPolicy,
      'actions',
    ];
  }

  private categoryCompare(
    testVal: TemplateLight,
    event: Array<string>,
  ): boolean {
    if (event && event.length > 0 && testVal.category) {
      return event.includes(testVal.category.id!.toString());
    }
    return true;
  }

  private frequencyCompare(
    testVal: TemplateLight,
    event: Array<string>,
  ): boolean {
    if (event && event.length > 0 && testVal.frequency) {
      return event.includes(testVal.frequency.type!.toString());
    }
    return true;
  }
}
