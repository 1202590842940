import {
  DatalakeService,
  SiteService,
  UnitService,
  UserService,
  UsersService,
} from '@al/services';
import { Component } from '@angular/core';

import { EnvironmentService } from '@al/environment';
import { GenericComponent } from '@al/generic-components';
import { GoogleService } from '@al/google';
import { LocalStorageKey } from '@al/local-cache';
import { LocaleService } from './locale.service';
import { Router } from '@angular/router';
import { SessionService } from '@al/session';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from './environments/environment';
import packageJson from '../../../package.json';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends GenericComponent {
  public connected = false;

  public copyright = this.environmentService.env.copyright;

  public title = this.environmentService.env.title;

  public version = packageJson.version;

  public constructor(
    private router: Router,
    protected siteService: SiteService,
    protected userService: UserService,
    protected usersService: UsersService,
    protected datalakeService: DatalakeService,
    protected environmentService: EnvironmentService,
    private sessionService: SessionService,
    private unitService: UnitService,
    private translateService: TranslateService,
    public googleService: GoogleService,
    private titleService: Title,
    // @Inject(LOCALE_ID) protected localeId: string,
    // @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef
    protected localeService: LocaleService
  ) {
    super();

    environmentService.env = environment;
    this.setLanguage();
    this.titleService.setTitle(environment.title);

    sessionService
      .checkSession()

      .then(() => {
        // enableAkitaProdMode();
        this.sessionService
          .getConnected()

          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((connected: boolean) => {
            this.connected = connected;

            if (connected) {
              // only if connected cause AuthGard will use connectedUser for
              // permission check
              this.siteService.setDefaultSite();
              this.userService
                .load()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                  next: () => {
                    siteService
                      .loadStore()
                      .pipe(takeUntil(this.ngUnsubscribe))
                      .subscribe({
                        next: () => {
                          // if(data.items.length == 0) {
                          //   throw new Error('No template-form for current user');
                          // }
                        },
                        error: (error) => {
                          console.error(
                            'intitial load Error: template-form',
                            error
                          );
                        },
                      });
                  },
                  error: (error) => {
                    console.error('intitial load Error: profile detail', error);
                  },
                });
            }
          });

        this.unitService
          .loadStore()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              // if(data.items.length == 0) {
              //   throw new Error('No template-form for current user');
              // }
            },
            error: (error) => {
              console.error('intitial load Error: unit', error);
            },
          });
      })
      .catch(() => {
        this.router.navigate(['/login']);
      });
  }

  public openLink(): void {
    // window.open('https://google.com', '_blank');
    window.open(this.environmentService.env.helpLink, '_blank');
  }
  // private gapiLoadAndSignIn(): Promise<void> {
  //   const config = {
  //     access_type: 'offline',
  //     scope: 'https://www.googleapis.com/auth/drive',
  //     client_id:
  //       '53352395356-h4g53aelc6qou8aki9787v1khjh8ii6f.apps.googleusercontent.com',
  //     plugin_name
  //     discoveryDocs: [
  //       'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
  //     ],
  //   };
  //   return new Promise<void>((resolve) => {
  //     if (gapi.client) {
  //       gapi.auth2
  //         .getAuthInstance()
  //         .currentUser.get()
  //         .reloadAuthResponse()
  //         .then(() => {
  //           resolve();
  //         });
  //     } else {
  //       gapi.load('client', () => {
  //         gapi.auth2.init(config);
  //         if (!!gapi.auth2 && gapi.auth2.getAuthInstance().isSignedIn.get()) {
  //           resolve();
  //         } else {
  //           gapi.auth2
  //             .getAuthInstance()
  //             .grantOfflineAccess({ prompt: 'consent' })
  //             .then(() => {
  //               resolve();
  //             })
  //             .catch((error) => {
  //               // TODO: Utiliser un logger global
  //               // eslint-disable-next-line no-console
  //               console.error(error);
  //               resolve();
  //             });
  //         }
  //       });
  //     }
  //   });
  // }

  private setLanguage(): void {
    const lang = localStorage.getItem(LocalStorageKey.LANGUAGE_KEY);
    if (lang !== null) {
      this.translateService.setDefaultLang(lang);
      this.translateService.use(lang);
    } else {
      this.translateService.setDefaultLang('en');
      this.translateService.use('en');
    }
  }
}
