import { EnvironmentOptions } from '@al/environment';

export const environment: EnvironmentOptions = {
  production: false,
  title: 'eRound (re7)',
  copyright: 'copyright Airliquide 2022',
  version: '7.0.1',

  apiUrl: 'http://localhost:3100/',
  apigwUrl: 'https://mugi25hwh7.execute-api.eu-west-1.amazonaws.com/preprod/',
  apigwUrlDatalake:
    'https://vm60jc0orj.execute-api.eu-west-1.amazonaws.com/re7/',

  acgUserPoolDomain:
    'eapps-auth-staging-users.auth.eu-west-1.amazoncognito.com',
  acgUserPoolLogoutEndpoint: '/logout',
  acgUserPoolClientId: '1psjutevtkfr22heg46cf9bcue',
  acgUserPoolId: 'eu-west-1_nZc7nEiU0',
  acgUserPoolClientCallback: '/callback',
  googleClientId:
    '53352395356-ej3ri6ptrdne7ef1dlsvon4aq67r94mf.apps.googleusercontent.com',
};
