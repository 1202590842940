<!--<mat-card class="al-template-frequency-cron-detail">-->

<mat-card class="al-template-frequency-cron-detail" [formGroup]="form">
  <mat-card-header>
    <mat-card-title translate>FREQUENCY</mat-card-title>
  </mat-card-header>

  <div *ngIf="form.get(formControlEnum.TYPE)">
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label>{{ "Frequency type" | translate }}</mat-label>
      <mat-select
        *ngIf="form.get(formControlEnum.TYPE)"
        [formControlName]="formControlEnum.TYPE"
        (selectionChange)="setFormControl(form)"
        [matTooltip]="'TYPE_TOOLTIP' | translate"
      >
        <mat-option
          *ngFor="let frequency of frequencyTypeList"
          [value]="frequency"
        >
          {{ frequency | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    *ngIf="this.form.get(formControlEnum.USE_DATE_FILTER)?.value"
    style="display: flex; flex-direction: row"
  >
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label class="required-field"
        >{{ "Applicable  Dates" | translate }}
        <!---->
      </mat-label>
      <div
        style="display: flex; flex-direction: row; align-content: baseline"
        class="full-width"
      >
        <mat-date-range-input
          [rangePicker]="picker"
          required="false"
          class="full-width"
          style="margin-top: 0.75em"
          [matTooltip]="'DATE_TOOLTIP' | translate"
        >
          <input
            readonly
            matStartDate
            [placeholder]="'START_EXECUTION_DATE' | translate"
            [formControlName]="this.formControlEnum.START_DATE"
          />
          <input
            readonly
            matEndDate
            [placeholder]="'END_EXECUTION_DATE' | translate"
            [formControlName]="this.formControlEnum.END_DATE"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker> </mat-date-range-picker>
      </div>
    </mat-form-field>
  </div>

  <div
    class="filter-container"
    *ngIf="form.get(formControlEnum.USE_DAY_FILTER)"
  >
    <mat-label class="required-field"
      >{{ "Applicable Days" | translate }}
    </mat-label>
    <mat-slide-toggle
      [checked]="form.get(formControlEnum.USE_DAY_FILTER)?.value"
      [formControlName]="formControlEnum.USE_DAY_FILTER"
      (change)="toggleDay($event.checked)"
    ></mat-slide-toggle>
  </div>
  <div
    class="checkbox-container"
    *ngIf="form.get(formControlEnum.USE_DAY_FILTER)?.value"
  >
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label class="required-field"
        >{{ "Applicable Days" | translate }}
      </mat-label>
      <mat-select
        [matTooltip]="'DAYS_TOOLTIP' | translate"
        [formControlName]="formControlEnum.APPLICABLE_DAYS"
        *ngIf="form.get(formControlEnum.APPLICABLE_DAYS)"
        [placeholder]="'DAYS_PLACEHOLDER' | translate"
        [multiple]="true"
        [compareWith]="compareDays"
      >
        <mat-option *ngFor="let day of daysList" [value]="day">
          {{ day.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    class="filter-container"
    *ngIf="this.form.get(formControlEnum.USE_TIME_FILTER)"
  >
    <mat-label class="required-field"
      >{{ "Applicable Hours" | translate }}
    </mat-label>
    <mat-slide-toggle
      [formControlName]="formControlEnum.USE_TIME_FILTER"
      (change)="toggleTime($event.checked)"
    ></mat-slide-toggle>
  </div>

  <div
    *ngIf="this.form.get(formControlEnum.USE_TIME_FILTER)?.value"
    class="execution-time-block"
  >
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <div style="display: flex; flex-direction: row; align-content: center">
        <!--      <mat-form-field appearance="outline"  floatLabel="always"  style="display: flex;    justify-content: space-between;">-->
        <mat-label class="align-time required-field"
          >{{ "From" | translate }}
        </mat-label>
        <ngx-mat-timepicker-toggle
          [for]="startTimePicker"
        ></ngx-mat-timepicker-toggle>
        <ngx-mat-timepicker #startTimePicker [format]="24">
        </ngx-mat-timepicker>

        <input
          readonly
          class="align-time-input"
          matInput
          [matTooltip]="'START_EXECUTION_TIME_TOOLTIP' | translate"
          [formControlName]="formControlEnum.START_EXECUTION_TIME"
          [ngxMatTimepicker]="startTimePicker"
          (change)="setValue(formControlEnum.START_EXECUTION_TIME)"
          placeholder="Choose start time"
        />
        <!--      </mat-form-field>-->
      </div>
    </mat-form-field>
    <!--  </div>-->
    <!--  <div *ngIf="this.form.get(formControleEnum.END_EXECUTION_TIME)">-->
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label class="align-time required-field"
        >{{ "START_EXECUTION_TIME_TO" | translate }}
      </mat-label>
      <div style="display: flex; flex-direction: row; align-content: center">
        <ngx-mat-timepicker-toggle
          [for]="endTimepicker"
        ></ngx-mat-timepicker-toggle>
        <ngx-mat-timepicker
          #endTimepicker
          [format]="24"
          (timeChanged)="setValue(formControlEnum.END_EXECUTION_TIME)"
        >
        </ngx-mat-timepicker>

        <input
          readonly
          class="align-time-input"
          matInput
          [matTooltip]="'END_EXECUTION_TIME_TOOLTIP' | translate"
          [formControlName]="formControlEnum.END_EXECUTION_TIME"
          [ngxMatTimepicker]="endTimepicker"
          placeholder="Choose time"
        />
      </div>
    </mat-form-field>
  </div>

  <div *ngIf="this.form.get(formControlEnum.EXECUTION_TIME)">
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label class="required-field align-time"
        >{{ "EXECUTION_TIME" | translate }}
      </mat-label>
      <!--    <mat-label class="required-field"-->
      <!--    >{{ "Select start date" | translate }}-->
      <!--    </mat-label>-->
      <div style="display: flex; flex-direction: row; align-content: center">
        <ngx-mat-timepicker-toggle
          [for]="endTimepicker"
        ></ngx-mat-timepicker-toggle>
        <ngx-mat-timepicker
          #endTimepicker
          [format]="24"
          (timeChanged)="setValue(formControlEnum.EXECUTION_TIME)"
        >
        </ngx-mat-timepicker>

        <input
          style="margin-top: 0.25em"
          matInput
          [formControlName]="formControlEnum.EXECUTION_TIME"
          [ngxMatTimepicker]="endTimepicker"
          [matTooltip]="'EXECUTION_TIME_TOOLTIP' | translate"
          placeholder="Choose time"
        />
      </div>
    </mat-form-field>
  </div>

  <!--  Hour interval -->
  <div *ngIf="this.form.get(formControlEnum.INTERVAL)">
    <mat-label
      class="required-field"
      *ngIf="
        this.form.get(formControlEnum.TYPE)?.value ===
        extendedFrequencyType.HOURLY
      "
      >{{ "HOURS_INTERVAL" | translate }}
    </mat-label>

    <mat-label
      class="required-field"
      *ngIf="
        this.form.get(formControlEnum.TYPE)?.value ===
        extendedFrequencyType.EVERY_X_DAYS
      "
      >{{ "DAYS_INTERVAL" | translate }}
    </mat-label>

    <mat-label
      class="required-field"
      *ngIf="
        this.form.get(formControlEnum.TYPE)?.value ===
        extendedFrequencyType.EVERY_X_WEEKS
      "
      >{{ "WEEKS_INTERVAL" | translate }}
    </mat-label>

    <mat-label
      class="required-field"
      *ngIf="
        this.form.get(formControlEnum.TYPE)?.value ===
        extendedFrequencyType.EVERY_X_MONTHS
      "
      >{{ "MONTHS_INTERVAL" | translate }}
    </mat-label>
    <mat-form-field appearance="outline" class="full-width">
      <input
        [formControlName]="this.formControlEnum.INTERVAL"
        (change)="setValue(this.formControlEnum.INTERVAL)"
        [matTooltip]="'INTERVAL_TOOLTIP' | translate"
        type="number"
        [placeholder]="'INTERVAL_TOOLTIP' | translate"
        matInput
      />
    </mat-form-field>
  </div>

  <div *ngIf="this.form.get(formControlEnum.OCCURENCY)">
    <mat-form-field appearance="outline" floatLabel="always" class="full-width">
      <!--      <mat-label>{{ 'Frequency type' | translate }}</mat-label>-->
      <mat-label>{{ "Occurency" | translate }}</mat-label>
      <mat-select
        *ngIf="form.get(formControlEnum.OCCURENCY)"
        [formControlName]="formControlEnum.OCCURENCY"
        [compareWith]="compareFrequencyOccurence"
        [matTooltip]="'OCCURENCY_TOOLTIP' | translate"
      >
        <mat-option
          *ngFor="let occurency of this.occurenciesList; let i = index"
          [value]="occurency"
        >
          {{ occurency.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="this.form.get(formControlEnum.DAY)">
    <mat-form-field appearance="outline" floatLabel="always" class="full-width">
      <!--      <mat-label>{{ 'Frequency type' | translate }}</mat-label>-->
      <mat-label>{{ "DAYS_OF_THE_WEEK" | translate }}</mat-label>
      <mat-select
        *ngIf="form.get(formControlEnum.DAY)"
        [formControlName]="formControlEnum.DAY"
        [compareWith]="compareDays"
        [matTooltip]="'EXECUTION_DAY_TOOLTIP' | translate"
      >
        <mat-option
          *ngFor="let day of this.daysList; let i = index"
          [value]="day"
        >
          {{ day.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="this.form.get(formControlEnum.ONE_SHOT_EXECUTION_TIME)">
    <mat-form-field appearance="outline" class="full-width" floatLabel="always">
      <mat-label class="required-field"
        >{{ "EXECUTION_DAY" | translate }}
      </mat-label>
      <input
        [formControlName]="this.formControlEnum.ONE_SHOT_EXECUTION_TIME"
        (change)="setValue(this.formControlEnum.ONE_SHOT_EXECUTION_TIME)"
        type="datetime-local"
        ng-model-options="{timezone : 'utc'}"
        matInput
        [matTooltip]="'EXECUTION_DAY_TOOLTIP' | translate"
      />
    </mat-form-field>
  </div>

  <div class="buttons">
    <button class="button" mat-button (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
      {{ "CANCEL" | translate }}
    </button>

    <button class="button" mat-button [disabled]="!form.valid" (click)="save()">
      <mat-icon>check</mat-icon>
      {{ "OK" | translate }}
    </button>
  </div>
  <!--    <div>-->
  <!--      <mat-label class="required-field"-->
  <!--      >{{ "Execution Time" | translate }}-->
  <!--      </mat-label>-->
  <!--      <mat-form-field appearance="outline" class="full-width" floatLabel="always">-->
  <!--        &lt;!&ndash;    <mat-label class="required-field"&ndash;&gt;-->
  <!--        &lt;!&ndash;    >{{ "Select start date" | translate }}&ndash;&gt;-->
  <!--        &lt;!&ndash;    </mat-label>&ndash;&gt;-->
  <!--        <ngx-mat-timepicker-toggle [for]="executionTimePicker"></ngx-mat-timepicker-toggle>-->
  <!--        <ngx-mat-timepicker-->
  <!--          #executionTimePicker-->
  <!--          [format]="24"-->
  <!--        >-->
  <!--        </ngx-mat-timepicker>-->

  <!--        <input-->
  <!--          matInput-->
  <!--          [ngxMatTimepicker]="executionTimePicker"-->
  <!--          placeholder="Choose time">-->

  <!--      </mat-form-field>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  <div>-->
  <!--    <mat-label class="required-field"-->
  <!--    >{{ "Day interval" | translate }}-->
  <!--    </mat-label>-->
  <!--    <mat-form-field appearance="outline" class="full-width">-->
  <!--      &lt;!&ndash;      <mat-label>{{ 'Enter number of hours' | translate }}</mat-label>&ndash;&gt;-->
  <!--      <input type="number"  [placeholder]=" 'Enter number of days' | translate " matInput>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->
  <!--  <div>-->
  <!--    <mat-label class="required-field"-->
  <!--    >{{ "Month interval" | translate }}-->
  <!--    </mat-label>-->
  <!--    <mat-form-field appearance="outline" class="full-width">-->
  <!--      &lt;!&ndash;      <mat-label>{{ 'Enter number of hours' | translate }}</mat-label>&ndash;&gt;-->
  <!--      <input type="number"   [placeholder]=" 'Enter number of months' | translate " matInput>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->
</mat-card>
