// locale.provider.ts
import { Inject, Injectable, inject } from '@angular/core';
// import localeEn from '@angular/common/locales/en';
// import localeEnAu from '@angular/common/locales/en-AU';
// import localEnGb from '@angular/common/locales/en-GB';
// import localFrFr from '@angular/common/locales/fr';
import { CustomNativeDateAdapter } from './custom-date.adapter';
import { DateAdapter } from '@angular/material/core';
import { LOCALE_ID } from '@angular/core';
import { Site } from '@al/model';
import { SiteService } from '@al/services';
// import { registerLocaleData } from '@angular/common';

// registerLocaleData(localeEnAu);
// registerLocaleData(localeEn);
// registerLocaleData(localEnGb);
// registerLocaleData(localFrFr);
/**
 * Service managing application localization
 * Allows setting and retrieving user locale
 * Subscribes to site changes to update locale
 * Uses CustomDateAdapter to format dates according to locale
 */

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private readonly dateAdapter = inject<DateAdapter<Date, String>>(DateAdapter);

  /** Current user locale, defaults to en-GB if not set */
  private userLocale: string = 'en-GB';

  public constructor(
    protected siteService: SiteService,
    @Inject(CustomNativeDateAdapter)
    protected customDateAdapter: CustomNativeDateAdapter // private localeService: LocaleService
  ) {
    this.siteService.getCurrentSiteObservable().subscribe((site: Site) => {
      this.userLocale = site!.country!.locale!;
      localStorage.setItem('locale', this.userLocale);
      this.dateAdapter.setLocale(this.userLocale);
    });
  }

  public getLocale(): string {
    return this.userLocale;
  }

  public setLocale(locale: string) {
    this.userLocale = locale;
  }
}

/**
 * Provider class for locale ID
 * Gets the user's locale from localStorage or defaults to en-GB
 */
export class LocaleIdProvider {
  /**
   * Gets the locale string from localStorage
   * @returns The user's locale if set, otherwise 'en-GB'
   */
  public get localeStr(): string {
    let locale = localStorage.getItem('locale');
    if (locale) {
      return locale;
    } else {
      return 'en-GB';
    }
  }
}

const handlerLocaleIdProvider = {
  get(target: LocaleIdProvider, prop: string | symbol): any {
    const localeStr = Reflect.get(target, 'localeStr');
    const value = localeStr[prop];
    return typeof value === 'function' ? value.bind(localeStr) : value;
  },
};
export const proxyLocalProvider = new Proxy(
  new LocaleIdProvider(),
  handlerLocaleIdProvider
);
export let LOCALE_PROVIDER = {
  provide: LOCALE_ID,
  useValue: proxyLocalProvider,
};
