import { Country, Entity, Site, SiteItems } from '@al/model';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SiteQuery, SiteStore } from '@al/akita';
import { map, takeUntil } from 'rxjs/operators';
import { GenericService } from './generic.service';
import { LocalStorageKey } from '@al/local-cache';
import { SiteRestService } from '@al/rest-services';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root',
})
export class SiteService extends GenericService<Site> implements OnDestroy {
  public currentSiteSubject = new Subject<Site>();

  public userSiteSubject = new Subject<Site[]>();

  protected currentSite: Site | undefined;

  private entities: Entity[] = [];

  private ngUnsubscribe = new Subject();

  public constructor(
    protected userService: UserService,
    protected siteRestService: SiteRestService,
    protected siteQuery: SiteQuery,
    protected siteStore: SiteStore,
    private injector: Injector
  ) {
    super(siteRestService, siteQuery, siteStore);
  }

  public create(site: Site): Observable<Site> {
    return this.siteRestService.create(site).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data) => {
        site.id = data.id;
        this.siteStore.upsert(site.id, site);
        this.siteStore.setActive(site.id);

        return data;
      })
    );
  }

  public getCountries(entity: Entity): Country[] {
    const entitiesFiltered = this.entities.filter(
      (value) => value.uuid === entity.uuid
    );
    if (entitiesFiltered.length === 1) {
      return entitiesFiltered[0].countries;
    }
    return [];
  }

  public getCurrentSite(): Site | undefined {
    return this.currentSite;
  }

  public getCurrentSiteObservable(): Observable<Site> {
    return this.currentSiteSubject.asObservable();
  }

  public getEntities(): Entity[] {
    return this.entities;
  }

  public getSiteFull(site: Site): Observable<Site> {
    return this.siteRestService.getFullSite(site.id).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: Site) => {
        // Pas de stockage dans le store
        return data;
      })
    );
  }

  public getUserSite(): Observable<Site[]> {
    return this.userSiteSubject.asObservable();
  }

  public loadEntity(): Observable<void> {
    return this.siteRestService.getEntities().pipe(
      takeUntil(this.ngUnsubscribe),
      map((entities: Entity[]) => {
        this.entities = entities;
      })
    );
  }

  public loadStore(): Observable<void> {
    return this.siteRestService.getAllSites().pipe(
      takeUntil(this.ngUnsubscribe),
      map((data: SiteItems) => {
        // data.items.forEach((site) => {
        //   this.siteStore.upsert(site.id, site);
        // });
        this.siteStore.reset();
        this.siteStore.set(data.items);
        // provide fresh site data to replace update user profile
        if (this.userService.getCurrent()) {
          let items: Site[] = [];
          this.userService.getCurrent()?.sites.forEach((site: Site) => {
            let siteAkita = this.siteQuery.getEntity(site.id);
            if (siteAkita) {
              items.push(siteAkita);
            }
          });
          this.userSiteSubject.next(items);
        }
        if (data.items.length > 0) {
          this.siteStore.setActive(data.items[0].id);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public save(site: Site): Observable<Site> {
    return this.siteRestService.save(site).pipe(
      takeUntil(this.ngUnsubscribe),
      map((data) => {
        this.siteStore.replace(site.id, site);
        this.siteStore.setActive(site.id);
        return data;
      })
    );
  }

  public setCurrentSite(site: Site): void {
    this.currentSite = site;
    localStorage.setItem(LocalStorageKey.DEFAULT_SITE, site.id);
    localStorage.setItem(
      LocalStorageKey.DEFAULT_SITE_OBJECT,
      JSON.stringify(site)
    );
    localStorage.setItem(
      LocalStorageKey.DEFAULT_TIMEZONE,
      // JSON.stringify(site.timezone)
      site.timezone
    );
    localStorage.setItem(LocalStorageKey.DEFAULT_DATEFORMAT, site.dateFormat);

    this.currentSiteSubject.next(this.currentSite);
  }

  public setDefaultSite(): void {
    if (localStorage.getItem(LocalStorageKey.DEFAULT_SITE)) {
      if (localStorage.getItem(LocalStorageKey.DEFAULT_SITE_OBJECT)) {
        this.currentSite = JSON.parse(
          localStorage.getItem(LocalStorageKey.DEFAULT_SITE_OBJECT)!
        );
      }
      if (this.currentSite) {
        this.currentSiteSubject.next(this.currentSite);
      }
    }
  }
}
